import React from "react"
import SEO from "../components/seo"
import FullScreenPanel from "../components/fullscreenpanel"
import TextPanel from "../components/textpanel"

const Privacy = () => (
  <>
    <SEO title="Home" keywords={[`website`, `design`, `development`]} />
    <FullScreenPanel>
      <TextPanel>
        <h1>
          Data Privacy
          <span>.</span>
        </h1>
        <p>We care deeply about privacy. This site implements no analytics or tracking cookies, so you can feel safe that neither we nor any third-party service providers are watching you. We&apos;d rather speak with you directly than creep around in your browser.</p>
      </TextPanel>
    </FullScreenPanel>
  </>
)

export default Privacy